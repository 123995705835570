<template>
  <home-section id="about" :button="button">
    <div class="row">
      <div class="col col-md-4 image animate-box fadeInUp animated">
        <div class="img">
          <img src="@/assets/images/home/about.jpg" alt="" />
        </div>
      </div>
      <div
        class="col col-md-8 animate-box fadeInUp animated"
        data-animate-effect="fadeInUp"
      >
        <!-- About -->
        <div class="row">
          <div class="col-md-12 text">
            <!-- <h3>Hi my name is Andrei</h3> -->
            <!-- Show all paragarafs of about information -->
            <p
              v-for="(paragraf, idx) in paragrafs"
              :key="idx"
              v-text="paragraf"
            ></p>
          </div>
        </div>
      </div>
    </div>
  </home-section>
</template>

<script>
export default {
  data: () => {
    return {
      button: {
        url: "about",
        text: "Read more",
      },
      paragrafs: [
        "Hi, I’m Andrei Moldovan and I love Art. I am a Moldovan artist painting Alaskan stuff and not only.",
        "Ever since I can remember, I have been passionate about the creation of Art. I always wanted to become an architect, and since childhood I had a tangent with linear drawing and geometry.",
        "My journey in Art began at the end of 2017, my main concern was to find my vocation. I was 28 years old, with studies in the field of environmental engineering, jobs in agriculture, construction, and fish processing, none of which piqued my interest. Now I am grateful to those who refused me then to job interviews, which redirected me to the beginning of my career in the field of Art. It all started with a set of oil colors received as a Christmas gift, from someone dear to me, who suggested to try oil painting.  I was initially skeptical, until  I layed down the first brush strokes on that paper! Yes, it was a paper, as I didn’t know about canvases back then. Finding  out  the tendency of the colors to mix together, and how malleable the oil is, amazed me! Oil painting has since become a part of me, trying day by day to become a better artist than I was yesterday.",
      ],
    };
  },
  name: "about",
};
</script>

<style lang="scss">
@import "@/assets/css/aboutPage";

// @media (min-width: 480px) and (max-width: 767px) {
//   .text {
//     text-align: center;
//     padding-top: 30px;
//   }
// }
// @media (min-width: 480px) and (max-width: 767px) {
//   .text {
//     padding-top: 30px;
//     text-align: center;
//     // color: #903242;
//   }
// }
@media (min-width: 1px) and (max-width: 480px) {
  #about .section-body img {
    display: none;
  }
}
@media (min-width: 1px) and (max-width: 767px) {
  .section-body .col,
  .section-body .col-md {
    padding: 0px;
  }

  .text {
    // padding-top: 30px;
    text-align: center;
    // color: #903242;
  }
}

.col.image img {
  max-width: 300px;
}
@media (min-width: 1px) and (max-width: 475px) {
  .col.image {
    display: none;
  }
}
</style>
