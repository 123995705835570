<template>
  <home-section id="portfolio" title="portfolio" :button="button">
    <div class="postfolio animate-box fadeInUp animated">
      <stack
        :column-min-width="270"
        :gutter-width="20"
        :gutter-height="20"
        monitor-images-loaded
      >
        <stack-item v-for="(picture, i) in pictures" :key="i">
          <div class="img position-re o-hidden">
            <img
              v-gallery
              :src="
                require(`@/assets/images/home/portfolio/new/${picture.url}`)
              "
              :alt="picture.shortDescription"
              :class="['lazyload', picture.type + '-img']"
            />
            <!-- <img
              v-img
              :src="
                require(`@/assets/images/home/portfolio/new/${picture.url}`)
              "
              :alt="picture.shortDescription"
              :class="picture.type + '-img'"
            /> -->
          </div>
        </stack-item>
      </stack>
    </div>
  </home-section>
</template>

<script>
export default {
  data: () => {
    return {
      pictures: [
        {
          shortDescription:
            "“The ninth Wave”, reproduction 2021, 212 x 140, oil, linen canvas",
          url: "1.jpg",
          type: "short",
        },
        {
          shortDescription: '"Aurora" 2021, 52 x 70, oil, canvas',
          url: "2.jpg",
          type: "short",
        },
        {
          shortDescription: '"Morning Stratus" 2021, 52 x 70, oil, canvas',
          url: "3.jpg",
          type: "short",
        },
        {
          shortDescription: '"EGxit" 2021, 64 x 90, oil, canvas',
          url: "4.jpg",
          type: "short",
        },
        {
          shortDescription:
            "“The ninth Wave”, reproduction 2021, 212 x 140, oil, canvas",
          url: "5.jpg",
          type: "extra-long",
        },
        {
          shortDescription: '"Winter landscape" 2021, 64 x 90, acrylic, canvas',
          url: "6.jpg",
          type: "short",
        },
        {
          shortDescription: "“Red veils” 2020, 52 x 70, oil, canvas",
          url: "7.jpg",
          type: "short",
        },
        {
          shortDescription: '"False Pass" 2018, 40 x 60, oil, canvas',
          url: "8.jpg",
          type: "long",
        },
        {
          shortDescription:
            'shortD"Fishing at sunset" 2021, 52 x 70, oil, canvasescription',
          url: "9.jpg",
          type: "short",
        },
        {
          shortDescription:
            "“Napoleon crossing the Alps”, reproduction 2018, 150 x130, oil, canvas",
          url: "10.jpg",
          type: "medium",
        },
        {
          shortDescription:
            "“Nebula Phenomena” 2020, 52 x 70, oil, linen canvas",
          url: "11.jpg",
          type: "shoer",
        },
        {
          shortDescription: '"5 am" 2021, 52 x 70, oil, canvas',
          url: "12.jpg",
          type: "short",
        },
      ],
      button: {
        text: "View all",
        url: "/portfolio",
      },
    };
  },
  name: "portfolioSection",
};
</script>

<style lang="scss">
// .vsg-container[data-v-63aaabc7] .img.position-re[data-v-637e99fa] {
//   margin-top: -10px;
// }

.vsg-stack-item img {
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.vsg-stack-item img.short-img {
  height: 200px;
}
.vsg-stack-item img.medium-img {
  height: 340px;
}
.vsg-stack-item img.long-img {
  height: 470px;
}
.vsg-stack-item img.extra-long-img {
  height: 550px;
}
</style>
