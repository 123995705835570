export default function durationFilter(date) {
  var d = Math.abs(new Date().getTime() - date) / 1000; // delta
  var r = {}; // result
  var s = {
    // structure
    year: 31536000,
    month: 2592000,
    week: 604800, // uncomment row to ignore
    day: 86400, // feel free to add your own row
    hour: 3600,
    minute: 60,
    second: 1,
  };

  Object.keys(s).forEach(function (key) {
    r[key] = Math.floor(d / s[key]);
    d -= r[key] * s[key];
  });

  const resp =
    r.year > 0
      ? r.year + " Y"
      : r.month > 0
      ? r.month + " m"
      : r.week > 0
      ? r.week + " w"
      : r.day > 0
      ? r.day + " d"
      : r.minute > 0
      ? r.minute + " min"
      : r.second + " s";

  return resp;
}
