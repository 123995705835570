import Vue from "vue";
import Vuex from "vuex";
import auth from "./auth";
import blog from "./blog";
import contact from "./contact";
import portfolio from "./portfolio";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    error: null,
    picture: {},
  },
  mutations: {
    setGalery(state, picture) {
      state.picture = picture;
    },
    clearGalery(state) {
      state.picture = {};
    },
    setError(state, error) {
      state.error = error;
    },
    clearError(state) {
      state.error = null;
    },
  },
  actions: {},
  modules: { blog, auth, portfolio, contact },
});
