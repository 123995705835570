<template>
  <div
    class="col-md-4 mb-40 animate-box fadeInUp animated blog-card"
    data-animate-effect="fadeInUp"
  >
    <div class="item">
      <div class="post-img">
        <div class="img" v-if="article.images.length > 0">
          <!-- :src="require(`../assets/images/blog/${article.images[0]}`)" -->
          <img :src="article.images[0].url" alt="" />
          <!-- <img src="../assets/images/portfolio/1.jpg" alt="" /> -->
        </div>
      </div>
      <div
        class="tools"
        @click="$router.push(`/blog/${$route.query.page || 1}/${article.id}`)"
      >
        <h6>
          {{ article.title }}
        </h6>
        <span class="info">
          {{ article.date }}
        </span>
        <button
          class="btn-next"
          @click="$router.push(`/blog/${$route.query.page || 1}/${article.id}`)"
        >
          <svg
            width="30"
            height="16"
            viewBox="0 0 30 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M29.7071 8.70711C30.0976 8.31659 30.0976 7.68342 29.7071 7.2929L23.3431 0.928936C22.9526 0.538412 22.3195 0.538412 21.9289 0.928936C21.5384 1.31946 21.5384 1.95263 21.9289 2.34315L27.5858 8L21.9289 13.6569C21.5384 14.0474 21.5384 14.6805 21.9289 15.0711C22.3195 15.4616 22.9526 15.4616 23.3431 15.0711L29.7071 8.70711ZM-1.74846e-07 9L29 9.00001L29 7.00001L1.74846e-07 7L-1.74846e-07 9Z"
              fill="#999999"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "blogGridItem",
  data: () => {
    return {};
  },
  props: {
    article: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
/* =======  Blog  ======= */
.blog-card {
  display: inline-block;
}

.blog .item {
  margin: 10px;
  background-color: #252525;
}
.blog .item .post-img {
  max-width: 100%;
  overflow: hidden;
  position: relative;
}
.post-img div.img img {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.blog .item .tools {
  padding: 15px 15px 10px 10px;
  cursor: pointer;
}
.blog .item .tools h6 {
  font-size: 20px;
  margin-bottom: 0px;
  min-height: 80px;
}
.blog .item .tools h6 a:hover {
  color: #327190;
}
.blog .item .tools h5 {
  font-size: 40px;
  margin-bottom: 5px;
}
.blog .item .info {
  font-size: 13px;
  color: #999;
  line-height: 25px;
  vertical-align: middle;
}
.blog .item .info a {
  margin-right: 0px;
  color: #999;
}
.blog .item .info a:last-child {
  margin-right: 0;
}
.blog .item .info b {
  color: #999;
  font-weight: 400;
  padding-right: 10px;
}

.blog .tools .btn-next {
  float: right;
}

@media (min-width: 1px) and (max-width: 767px) {
  .blog-card {
    width: 100%;
    margin: auto;
  }
}
</style>
