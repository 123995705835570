<template>
  <div class="row feedback-item">
    <div class="col col-8">
      <p class="text">{{ feedback.text }}</p>
      <h6 class="author">{{ feedback.author }}</h6>
    </div>
  </div>
</template>

<script>
export default {
  name: "feedbackItem",
  data: () => {
    return {};
  },
  props: {
    feedback: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.feedback-item .col {
  margin: auto;
  text-align: center;
  padding-left: 60px;
}
.feedback-item .col p {
  text-align: center;
  margin: auto;
}
.feedback-item .col h6 {
  text-align: center;
  font-weight: bold;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 30px;
}

@media (min-width: 1px) and (max-width: 767px) {
  .feedback-item .col {
    padding-left: 0px;
  }
  .btn-back.slick-arrow {
    left: calc(50% - 60px) !important;
  }
  .btn-next.slick-arrow {
    left: calc(50% + 0px) !important;
  }
}
</style>
