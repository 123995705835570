<template>
  <home-section id="contacts" title="contacts">
    <!-- Info -->
    <div
      class="col-md-6 mb-60 animate-box fadeInUp animated"
      data-animate-effect="fadeInUp"
    >
      <ContactInfo />
    </div>

    <!-- Contact Form -->
    <div
      class="col-md-6 mb-60 animate-box fadeInUp animated"
      data-animate-effect="fadeInUp"
    >
      <div>
        <h4>Drop me a line!</h4>
      </div>
      <ContactForm />
    </div>
    <div
      class="block-text animate-box fadeInUp animated col-sm some-text"
      data-animate-effect="fadeInUp"
    >
      <h4>
        <strong
          >Thanks for enjoying my work, thank you those who support me over the
          years.</strong
        >
        <br />
        If you have a any questions about painting availability and prices,
        write an email, I would love to hear from you.
      </h4>
    </div>
  </home-section>
</template>

<script>
import ContactInfo from "@/components/contacts/ContactInfo";
import ContactForm from "@/components/contacts/ContactForm";

export default {
  name: "contacts",
  data: () => {
    return {};
  },
  components: {
    ContactInfo,
    ContactForm,
  },
};
</script>

<style scoped>
.some-text {
  padding: 101px 30px 30px 30px;
  margin: auto;
  text-align: center;
}
</style>
