<template>
  <div
    class="section-head text-center col-md-12 animate-box fadeInUp animated"
    data-animate-effect="fadeInUp"
  >
    <h4>{{ title | capitalize }}</h4>
  </div>
</template>

<script>
export default {
  name: "sectiontitle",
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.section-head {
  overflow: hidden;
  margin: 30px 0;
}
.section-head h4 {
  font-size: 35px;
  font-weight: 400;
  position: relative;
  margin: 0;
}
.section-head h4 span {
  color: #327190;
  padding-right: 30px;
}
.section-head h4 span i {
  font-weight: 400;
}
</style>
