<template>
  <form @submit.prevent="submitHandler" class="row">
    <div class="col-sm-6">
      <div class="form-component">
        <input v-model="formData.name" type="text" placeholder="Name" />
      </div>
    </div>
    <div class="col-sm-6">
      <div class="form-component">
        <input
          v-model="formData.contact"
          type="text"
          placeholder="Email / Phone *"
        />
      </div>
    </div>
    <div class="col-sm-12">
      <div class="form-component">
        <textarea
          v-model="formData.message"
          cols="40"
          rows="3"
          placeholder="Message"
        ></textarea>

        <button type="submit">
          <base-icon name="arrow-next" />
        </button>
      </div>
    </div>
  </form>
</template>

<script>
// import emailjs from "emailjs-com";
export default {
  data: () => {
    return {
      busy: false,
      formData: {
        name: "",
        contact: "",
        message: "",
      },
    };
  },
  methods: {
    async submitHandler() {
      await this.$store.dispatch("contactArtict", {
        from_name: this.formData.name,
        from_contact: this.formData.contact,
        message: this.formData.message,
      });

      this.formData = {
        name: "",
        contact: "",
        message: "",
      };
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/css/contactFormComponent";
</style>
