<template>
  <div id="app">
    <div id="page-wrapper">
      <PgeWrapper />
      <Navbar />
      <div class="page-content pt-130">
        <router-view @setsocial="setSocial" />
      </div>
      <Footer :social="social" />
    </div>
    <LightBoxImg ref="LightBoxImg" />
  </div>
</template>

<script>
import Navbar from "./components/common/Navbar";
import Footer from "./components/common/Footer.vue";
export default {
  data() {
    return {
      social: true,
      testConst: 1,
    };
  },
  methods: {
    setSocial(val) {
      this.social = val;
    },
  },
  components: { Navbar, Footer },
  watch: {
    "$store.state.picture": function (val) {
      if (!val || Object.keys(val).length === 0) return;
      this.$refs.LightBoxImg.open();
    },
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: "Caveat Brush";
  src: local("Caveat Brush"),
    url("./assets/fonts/CaveatBrush-Regular.ttf") format("cursive");
}
@import url("https://fonts.googleapis.com/css2?family=Caveat+Brush&display=swap");
@import "assets/css/common";
@import "assets/css/animate";
.page-content {
  z-index: 10;
  position: relative;
}
</style>
