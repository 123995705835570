import { getAuth, signInAnonymously, signOut } from "firebase/auth";

export default {
  actions: {
    async loginAnonymously() {
      return new Promise((resolve, reject) => {
        const auth = getAuth();
        signInAnonymously(auth)
          .then(() => {
            resolve({ code: 0 });
          })
          .catch((error) => {
            reject({ code: -99, error, function: "loginAnonymously" });
          });
      });
    },
    async logout() {
      const auth = getAuth();
      await signOut(auth);
      // Sign-out successful.
      //clear data action
      // commit("clearInfo");
    },
    getUid() {
      const auth = getAuth();
      const user = auth.currentUser;
      return user ? user.uid : null;
    },
  },
};
