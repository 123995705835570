<template>
  <div class="row pageTitle">
    <div
      class="section-head col-md-12 text-center animate-box fadeInUp animated"
      data-animate-effect="fadeInUp"
    >
      <div class="row title-component">
        <!-- <div class="col col-6 offset-6 text-left title-component"> -->
        <!-- <div class="col col-6 offset-6  right"> -->
        <!-- <svg
            width="100"
            height="2"
            viewBox="0 0 100 2"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line y1="1" x2="100" y2="1" stroke="#999999" stroke-width="2" />
          </svg> -->
        <!-- </div> -->
        <div class="col col-6 offset-6 left">
          <h4>{{ title | capitalize }}</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => {
    return {};
  },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/css/pageTitleComponent";
</style>
