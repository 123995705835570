<template>
  <div>
    <Loader v-if="loading" />
    <div v-else class="home">
      <div class="container">
        <div class="row page-body">
          <!-- START: Hero section -->
          <div class="hero pt-130">
            <div class="row">
              <HeroCarusel />
              <div
                class="block-text animate-box fadeInRight animated col-sm"
                data-animate-effect="fadeInRight"
              >
                <div class="motto-block">
                  <h2 data-swiper-parallax-x="-60%" class="next-main-title">
                    Andrei MoldoVAN
                  </h2>
                  <h3 data-swiper-parallax-x="-50%" class="main-subtitle">
                    self-taught artist
                  </h3>
                  <p data-swiper-parallax-x="-40%" class="motto">
                    It is difficult to paint what you see, but it is even harder
                    to express through painting what you see and feel
                  </p>
                  <a
                    data-swiper-parallax-x="-30%"
                    style="z-index: 5"
                    class="next-link"
                    href="/portfolio"
                    target="_blank"
                    >Buy Now</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Hero section -->
      <!-- START: About section -->
      <AboutSection />
      <!-- START: Portfolio section -->
      <PortfolioSection />
      <!-- START: Testemonials section -->
      <div class="row feedback-section animate-box fadeInUp animated">
        <FeedbackSection />
      </div>
      <!-- END: Testemonials section -->
      <!-- START: Blog section -->
      <BlogSection />
      <!-- START: Contacts section -->
      <ContactSection />
    </div>
  </div>
</template>

<script>
import ContactSection from "@/components/home/ContactsSection";
import AboutSection from "@/components/home/AboutSection";
import BlogSection from "@/components/home/BlogSection";
import FeedbackSection from "@/components/home/FeedbackSection";
import PortfolioSection from "@/components/home/PortfolioSection";
import HeroCarusel from "@/components/home/HeroCarusel";

export default {
  data: function () {
    return {
      loading: true,

      // articles: [],
    };
  },
  async mounted() {
    this.$emit("setsocial", false);
    this.loading = false;
  },

  name: "home",
  components: {
    HeroCarusel,
    ContactSection,
    AboutSection,
    BlogSection,
    FeedbackSection,
    PortfolioSection,
  },
};
</script>

<style lang="scss">
@import "@/assets/css/homePage";
</style>
