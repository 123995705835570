import { child, get, getDatabase, ref } from "firebase/database";

import _ from "lodash";

export default {
  actions: {
    async fetchPictures({ commit }) {
      const dbRef = ref(getDatabase());
      let pictures = [];
      try {
        await get(child(dbRef, process.env.VUE_APP_DB_PORTFOLIO_LOCATION))
          .then((portfolio) => {
            portfolio = portfolio.val();
            pictures = Object.keys(portfolio).map((key) => ({
              ...portfolio[key],
              id: key,
            }));

            //Order descendente by data
            pictures = _.orderBy(pictures, ["date"], ["desc"]);
          })
          .catch((error) => {
            console.error(error);
            commit("setError", error);
          });
      } catch (error) {
        commit("setError", error);
      }
      return pictures;
    },

    async fetchPictureById({ commit }, id) {
      const dbRef = ref(getDatabase());
      let picture = {};
      try {
        await get(
          child(dbRef, `${process.env.VUE_APP_DB_PORTFOLIO_LOCATION}/${id}`)
        )
          .then((art) => {
            picture = art.val();
          })
          .catch((error) => {
            commit("setError", error);
          });
      } catch (error) {
        commit("setError", error);
      }
      return { ...picture, id };
    },
  },
};
