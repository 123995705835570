<template>
  <home-section id="blog" title="blog" :button="button">
    <div class="blog" v-if="articles && articles.length > 0">
      <BlogGrid :articles="articles"></BlogGrid>
    </div>
    <p v-else>No published article yep</p>
  </home-section>
</template>

<script>
import BlogGrid from "@/components/blog/BlogGrid";

export default {
  data: () => {
    return {
      articles: [],
      button: {
        text: "View all",
        url: "/blogs",
      },
    };
  },
  name: "blogSection",
  async mounted() {
    try {
      const articles = await this.$store.dispatch("fetchArticles", true);
      this.articles = articles;
    } catch (err) {
      console.error(err);
    }
  },
  components: { BlogGrid },
};
</script>

<style lang="scss"></style>
