import _ from "lodash";

export default {
    data() {
      return {
        pageNumber: +this.$route.query.page || 1,
        pageSize: 12,
        pageCount: 0,
        totalRows:0,
        allItems: [],
        items: [],
      };
    },
    methods: {
      setUpPagination(allItems) {
        this.totalRows = allItems.length;
        this.allItems = _.chunk(allItems, this.pageSize);
        this.pageCount = _.size(this.allItems);
        this.items = this.allItems[this.pageNumber - 1] || this.allItems[0];
      },
      pageChangeHandler(page) {
        this.$router.push(`${this.$route.path}?page=${page}`);
        this.items = this.allItems[page - 1] || this.allItems[0];
      },
    },
  };