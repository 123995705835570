<template>
  <header class="container">
    <div
      :class="[
        'navigation-wrap start-header start-style',
        { 'scroll-on': scrollPosition > 0 },
      ]"
    >
      <div class="container">
        <b-navbar
          toggleable="lg"
          class="navbar navbar-expand-md navbar-light"
          variant="dark"
        >
          <!-- Logo -->
          <b-navbar-brand href="/">
            <img src="@/assets/images/logoWhite.svg" class="logo" alt=""
          /></b-navbar-brand>

          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav>
              <router-link
                v-for="link in links"
                :key="link.url"
                tag="b-nav-item"
                class="nav-item pl-md-0 ml-0 ml-md-4"
                active-class="active"
                :to="link.url"
              >
                <!-- <a class="nav-link" href="#"> -->
                {{ link.title | capitalize }}
                <!-- </a> -->
              </router-link>
              <!-- <b-nav-item href="#">Link</b-nav-item> -->
              <!-- <b-nav-item href="#" disabled>Disabled</b-nav-item> -->
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "nav-bar",
  props: ["value"],
  data: () => ({
    scrollPosition: 0,
    links: [
      // {
      //   title: "home",
      //   url: "/",
      // },
      {
        title: "about",
        url: "/about",
      },
      {
        title: "hobbies",
        url: "/hobbies",
      },
      {
        title: "portfolio",
        url: "/portfolio",
      },
      {
        title: "blog",
        url: "/blogs",
      },
      {
        title: "contacts",
        url: "/contacts",
      },
    ],
  }),
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.scrollPosition = Math.round(window.pageYOffset);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.scrollPosition = Math.round(window.pageYOffset);
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/css/navbar";
</style>
