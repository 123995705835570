<template>
  <div class="contact-info-container">
    <!-- Contact info container -->
    <div
      class="contact-info-omponent"
      v-for="(contact, idx) in contactInfo"
      :key="idx"
    >
      <h4>{{ contact.type }}</h4>
      <p v-for="(info, idx) in contact.info" :key="idx">
        {{ info }}
      </p>
    </div>
    <div class="contact-info-omponent">
      <SocialLinks />
    </div>
  </div>
</template>

<script>
import SocialLinks from "./SocialLinks.vue";
export default {
  data: () => {
    return {
      contactInfo: [
        {
          type: "Phone",
          info: ["+373 69813343"],
        },
        {
          type: "Email",
          info: ["andrei.moldovan.artist@gmail.com"],
        },
      ],
    };
  },
  components: {
    SocialLinks,
  },
};
</script>
<style lang="scss">
@import "@/assets/css/contactInfoComponent";
</style>
