// import 'bootstrap'
//styles
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap-vue/dist/bootstrap-vue-icons.min.css";
import "slick-carousel/slick/slick.css";

//Scripts
import {
  BootstrapVue,
  BootstrapVueIcons,
} from "bootstrap-vue/dist/bootstrap-vue.esm";
// Componentss
import { Stack, StackItem } from "vue-stack-grid";

import App from "./App.vue";
import BaseIcons from "./components/common/BaseIcon";
import HomeSection from "./components/home/HomeSection";
import LightBoxImg from "./components/common/LightBoxImg";
import Loader from "./components/common/Loader";
import Page from "./components/common/Page";
import PageTitle from "./components/common/PageTitle";
import PgeWrapper from "./components/common/PageWrapper.vue";
import PictureGridItem from "./components/portfolio/PictureGridItem.vue";
import Vue from "vue";
import VueImg from "v-img";
import VueMeta from "vue-meta";
import VueSocialSharing from "vue-social-sharing";
import VueValidate from "vuelidate";
// filters
import capitalizeFilter from "./filters/capitalize.filter";
import durationFilter from "./filters/duration.filter";
//Configurations
import firebaseConfig from "./config/firebase";
import { initializeApp } from "firebase/app";
import panZoom from "vue-panzoom";
//module
import router from "./router";
import store from "./store";
import vueImgConfig from "./config/vueImgConfig";

// import VueViewer from 'v-viewer'

// import { getAnalytics } from "firebase/analytics";
Vue.use(panZoom);
Vue.component("stack", Stack);
Vue.component("base-icon", BaseIcons);
Vue.component("stack-item", StackItem);
Vue.component("page-structure", Page);
Vue.component("page-title", PageTitle);
Vue.component("home-section", HomeSection);
Vue.component("PgeWrapper", PgeWrapper);
Vue.component("picture-grid-item", PictureGridItem);
Vue.component("Loader", Loader);
Vue.component("LightBoxImg", LightBoxImg);
Vue.filter("capitalize", capitalizeFilter);
Vue.filter("duration", durationFilter);

Vue.use(VueValidate);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
// Vue.use(VueViewer)
Vue.use(VueSocialSharing);
Vue.use(VueMeta);
Vue.use(VueImg, vueImgConfig);

Vue.directive("gallery", {
  // When the bound element is inserted into the DOM...
  inserted: function (el, binding, vnode) {
    el.addEventListener("click", function (e) {
      vnode.context.$store.commit("setGalery", {
        src: e.target.attributes.src.value,
        title: e.target.attributes.alt.value,
      });
    });
  },
});

// Initialize Firebase
initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
let app;
// const auth = getAuth();

Vue.config.productionTip = false;
// onAuthStateChanged(auth, (user) => {
if (!app) {
  app = new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");
}
// });
