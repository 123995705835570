<template>
  <div class="container">
    <div
      class="row page-body block-text animate-box fadeInUp animated col-sm"
      data-animate-effect="fadeInUp"
    >
      <section class="home-section container" :id="id">
        <SectionTitle :title="title" v-if="title" />
        <div class="row section-body">
          <slot></slot>
          <div
            class="section-tools animate-box fadeInUp animated"
            v-if="button"
          >
            <!-- <router-link class="next-link" tag="a" :to="button.url" exact>
              {{ button.text }}
            </router-link> -->
            <a class="next-link" :href="button.url">{{ button.text }}</a>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import SectionTitle from "./SectionTitle.vue";

export default {
  components: { SectionTitle },
  name: "homeSection",
  props: {
    id: { type: String, required: true },
    title: {
      type: String,
      required: false,
    },
    button: {
      type: Object,
      required: false,
      default: null,
    },
  },
};
</script>

<style lang="scss">
// @import "@/assets/css/contactPage";
.home-section {
  margin: 50px 0px;
}

.section-tools {
  padding-top: 50px;
  text-align: right;
}

.row.section-body,
.row.section-body > .row {
  margin: auto;
  padding: 0px;
}
</style>
