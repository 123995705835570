<template>
  <div
    class="block-img animate-box fadeInLeft animated"
    data-animate-effect="fadeInLeft"
    data-swiper-parallax-y="70%"
  >
    <div>
      <b-carousel
        fade
        v-model="slide"
        ref="myCarousel"
        :interval="10000"
        indicators
        img-width="100%"
      >
        <b-carousel-slide
          v-for="(image, idx) in images"
          :key="idx"
          :img-src="require(`@/assets/images/home/hero/${image.src}`)"
        ></b-carousel-slide>
        <!--   :img-src="require(`@/assets/images/home/hero/${image.src}`)"
        ></b-carousel-slide>-->
      </b-carousel>
      <div class="carusel-tool">
        <button type="button" @click="isPaused = !isPaused">
          {{ isPaused ? "⏵" : "⏸" }}
        </button>
        <!-- {{ slide + 1 }}/3 -->
        <p>{{ `${slide + 1}/${images.length}` }}</p>
      </div>
    </div>
    <!-- <img src="../assets/images/home/hero.jpg" alt="" /> -->
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      images: [
        { src: "1.jpg" },
        { src: "2.jpg" },
        { src: "3.jpg" },
        { src: "4.jpg" },
        { src: "5.jpg" },
        { src: "6.jpg" },
        { src: "7.jpg" },
        { src: "8.jpg" },
        { src: "9.jpg" },
        { src: "10.jpg" },
      ],
      isPaused: false,
      slide: 0,
    };
  },
  watch: {
    isPaused: function (val) {
      if (val) this.$refs.myCarousel.pause();
      else this.$refs.myCarousel.start();
    },
  },
  computed: {
    legend: function () {
      return `${this.slide + 1}/${this.images.length}`;
    },
    buttonText: function () {
      return this.isPaused ? "⏵" : "⏸";
    },
  },
};
</script>

<style>
.carusel-tool {
  height: 40px;
  width: 100%;
  margin-top: -40px;
  position: relative;
  z-index: 1000;
  background-color: transparent;
  /* display: none; */
}

.carusel-tool p {
  display: none;
}
.block-img:hover .carusel-tool button,
.block-img:hover .carusel-tool p {
  display: inline-block;
}

.carusel-tool button {
  color: #999;
  background-color: transparent;
  padding: 5px;
  display: none;
}
.carusel-tool button:hover {
  background-color: transparent;
  color: #327190;
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
  max-height: 490px;
}
</style>
