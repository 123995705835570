<template>
  <footer>
    <div class="container footer-container">
      <div class="row">
        <div class="col-md-6">
          <SocialLinks v-if="social" />
          <button v-else type="button" @click="showConditions">
            Terms and conditions
          </button>
        </div>
        <div class="col-md-6">
          <p class="copyright">
            © {{ new Date().getFullYear() }} <strong>moldoVAN</strong>. All
            rights reserved.
            <span v-if="site">
              Created with <span>♥</span> by
              <a href="http://weblevel.md/">weblevel.md</a>
            </span>
          </p>
        </div>
      </div>
    </div>
    <TermsConditionModal ref="termsModal" />
  </footer>
</template>

<script>
import SocialLinks from "@/components/contacts/SocialLinks.vue";
import terms from "@/assets/data/terms";
import TermsConditionModal from "./TermsConditionModal";
export default {
  data: () => {
    return { terms: terms, site: false };
  },
  props: {
    social: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    showConditions() {
      this.$refs.termsModal.open();
      // this.$bvModal.msgBoxOk(this.terms, {
      //   title: "Terms and conditions",
      //   size: "m",
      //   buttonSize: "m",
      //   centered: true,
      // });
    },
  },
  components: {
    SocialLinks,
    TermsConditionModal,
  },
};
</script>

<style lang="scss">
@import "@/assets/css/footer";
.footer-container a {
  color: #999;
  text-decoration: none;
}
</style>
