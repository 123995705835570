<template>
  <home-section id="feedback" title="testimonials">
    <div class="row animate-box fadeInUp animated">
      <slick ref="slick" :options="slickOptions">
        <FeedbackItem
          v-for="(feedback, idx) in feedbacks"
          :key="idx"
          :feedback="feedback"
        ></FeedbackItem>
      </slick>
    </div>
  </home-section>
</template>

<script>
import Slick from "vue-slick";
import FeedbackItem from "./FeedbackItem.vue";
import "slick-carousel/slick/slick.css";

export default {
  name: "feedbackSection",
  data: () => {
    return {
      feedbacks: [
        {
          text: "That's awesome thank you for being great!",
          author: "Andrei",
        },
        {
          text: "I wish I could cover all my walls with your work.",
          author: "Juan",
        },
        {
          text: "Unbelievable how a picture can share a lot of miracle, I just love it",
          author: "Anatoliy",
        },
      ],
      slickOptions: {
        nextArrow:
          '<button type="button" class="btn-next"><svg width="46" height="8" viewBox="0 0 46 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M45.3536 4.35355C45.5488 4.15829 45.5488 3.84171 45.3536 3.64645L42.1716 0.464466C41.9763 0.269204 41.6597 0.269204 41.4645 0.464466C41.2692 0.659728 41.2692 0.976311 41.4645 1.17157L44.2929 4L41.4645 6.82843C41.2692 7.02369 41.2692 7.34027 41.4645 7.53553C41.6597 7.7308 41.9763 7.7308 42.1716 7.53553L45.3536 4.35355ZM0 4.5H45V3.5H0V4.5Z" fill="#999999"/></svg></button>',
        prevArrow:
          '<button type="button" class="btn-back"><svg width="46" height="8" viewBox="0 0 46 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.646447 4.35355C0.451184 4.15829 0.451184 3.84171 0.646447 3.64645L3.82843 0.464466C4.02369 0.269204 4.34027 0.269204 4.53553 0.464466C4.7308 0.659728 4.7308 0.976311 4.53553 1.17157L1.70711 4L4.53553 6.82843C4.7308 7.02369 4.7308 7.34027 4.53553 7.53553C4.34027 7.7308 4.02369 7.7308 3.82843 7.53553L0.646447 4.35355ZM46 4.5H1V3.5H46V4.5Z" fill="#999999"/></svg></button>',
      },
    };
  },
  components: {
    FeedbackItem,
    Slick,
  },
};
</script>

<style lang="scss">
// #feedback {
//   width: 100vw;
//   position: relative;
//   left: 50%;
//   right: 50%;
//   margin-left: -50vw;
//   margin-right: -50vw;
//   background-color: #252525;
//   top: 500px;
//   position: absolute;
//   left: 0;
//   right: 0;
// }
.feedback-section {
  //   width: 100vw;
  //   position: relative;
  //   left: 50%;
  //   right: 50%;
  //   margin-left: -50vw;
  //   margin-right: -50vw;
  background-color: #252525;
  //   position: absolute;
  //   left: 0;
  //   right: 0;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
  padding: 10px;
}

.slick-arrow {
  position: sticky;
  top: 100%;
  // left: calc(50% - 100px);
}
.btn-next.slick-arrow {
  left: calc(50% + 20px);
}
.btn-back.slick-arrow {
  left: calc(50% - 50px);
}

.slick-slide {
  margin: 0px;
}
</style>
