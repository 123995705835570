<template>
  <Loader v-if="loading" />
  <div v-else v-cloak>
    <div class="container">
      <page-title v-if="title.length > 0" :title="title" />
      <div class="row page-body">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {};
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
[v-cloak] {
  display: none;
}
</style>
