<template>
  <div class="row">
    <!-- Blog grid -->
    <div v-if="articles.length > 0">
      <BlogGridItem
        v-for="article in items"
        :key="article.id"
        :article="article"
      ></BlogGridItem>

      <!-- Pagination -->
      <div
        class="animate-box fadeInUp animated pagination-container"
        data-animate-effect="fadeInUp"
        v-if="pageCount > 1"
      >
        <b-pagination
          v-model="pageNumber"
          :total-rows="totalRows"
          :per-page="pageSize"
          first-number
          last-number
          prev-text="←"
          next-text="→"
          aria-controls="my-table"
          align="center"
          limit="5"
          @change="pageChangeHandler"
        ></b-pagination>
      </div>
    </div>
    <div v-else>No irticles</div>
  </div>
</template>

<script>
import BlogGridItem from "./BlogGridItem";
import paginationMixin from "@/mixins/pagination.mixin";
export default {
  name: "BlogGrid",
  data: () => {
    return {};
  },
  mixins: [paginationMixin],
  props: {
    articles: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    this.setUp(this.articles);
  },
  methods: {
    setUp(articles) {
      this.setUpPagination(articles);
    },
  },
  components: { BlogGridItem },
};
</script>
