<template>
  <div
    class="picture item animate-box fadeInUp animated portfolio-grid"
    data-animate-effect="fadeInUp"
  >
    <div class="img position-re o-hidden">
      <img v-gallery :src="picture.image.url" :alt="picture.shortDescription" />
      <!-- <img v-img :src="picture.image.url" :alt="picture.shortDescription" /> -->
    </div>
    <div
      class="tools"
      @click="$router.push(`/picture/${$route.query.page || 1}/${picture.id}`)"
    >
      <span>
        {{ picture.title }}
      </span>
      <button
        class="btn-next"
        @click="
          $router.push(`/picture/${$route.query.page || 1}/${picture.id}`)
        "
      >
        <svg
          width="30"
          height="16"
          viewBox="0 0 30 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M29.7071 8.70711C30.0976 8.31659 30.0976 7.68342 29.7071 7.2929L23.3431 0.928936C22.9526 0.538412 22.3195 0.538412 21.9289 0.928936C21.5384 1.31946 21.5384 1.95263 21.9289 2.34315L27.5858 8L21.9289 13.6569C21.5384 14.0474 21.5384 14.6805 21.9289 15.0711C22.3195 15.4616 22.9526 15.4616 23.3431 15.0711L29.7071 8.70711ZM-1.74846e-07 9L29 9.00001L29 7.00001L1.74846e-07 7L-1.74846e-07 9Z"
            fill="#999999"
          />
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "pictureGridItem",
  data: () => {
    return {
      pictureItem: null,
    };
  },
  props: {
    picture: {
      type: Object,
      required: true,
    },
  },
  components: {},
  mounted() {
    // this.pictureItem = JSON.parce(picture);
  },
};
</script>

<style lang="scss">
.picture.item .tools {
  padding: 15px 15px 10px 10px;
  background-color: #252525;
  position: absolute;
  bottom: -100%;
  left: 0;
  width: 100%;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  text-align: left;
  display: none;
}
.picture.item:hover .tools {
  padding: 15px 15px 10px 10px;
  bottom: 0;
  display: block;
}
.picture.item .tools span {
  max-width: 80%;
  font-size: 20px;
  margin-bottom: 0px;
  line-height: 25px;
  vertical-align: middle;
  font-family: "Caveat Brush", cursive;
  // font-family: "Grechen Fuemen", cursive;
  color: #999;
  // color: #327190;
}
.picture.item .tools h6 a:hover {
  color: #327190;
}
.picture.item .tools a {
  margin-right: 0px;
  color: #999;
}

.picture .tools .btn-next {
  float: right;
  line-height: 100%;
  vertical-align: middle;
}

.title-v-img {
  position: fixed;
  bottom: 5px;
  left: 15%;
  right: 15%;
  padding: 5px;
}

@media (min-width: 1px) and (max-width: 767px) {
  .picture.item .tools {
    padding: 15px 15px 10px 10px;
    bottom: 0;
    display: block;
    cursor: pointer;
  }
}
</style>
