<template>
  <div
    class="fullscreen-v-img"
    v-if="visible && Object.keys($store.state).length > 0"
  >
    <div class="header-v-img">
      <span class="count-v-img"> </span>
      <span class="title-v-img">{{ $store.state.picture.title }}</span>
      <div class="buttons-v-img">
        <span @click="close">×</span>
      </div>
    </div>
    <div class="content-v-img">
      <panZoom
        v-if="visible && Object.keys($store.state).length > 0"
        :options="{
          minZoom: 0.9,
          maxZoom: 3,
          smoothScroll: false,
          transformOrigin: { x: 0.5, y: 0.5 },
        }"
      >
        <img :src="$store.state.picture.src" />
      </panZoom>
    </div>
  </div>
</template>

<script>
export default {
  name: "box-image",
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    close() {
      this.visible = false;
      this.$store.commit("clearGalery");
    },
    open() {
      this.visible = true;
    },
  },
  // created() {
  //   window.addEventListener("scroll", this.close);
  // },
  // destroyed() {
  //   window.removeEventListener("scroll", this.close);
  // },
};
</script>

<style>
.fullscreen-v-img {
  background: rgba(30, 30, 30, 0.99) !important;
  /* touch-action: auto !important; */
  /* pointer-events: none !important; */
  z-index: 9999;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.7);
  /* -ms-touch-action: none; */
}

.header-v-img,
.footer-v-img {
  position: absolute;
  width: 100%;
  background-color: transparent !important;
  height: 50px;
  z-index: 9999;
  display: flex;
  align-items: center;
  /* margin-top: 30px; */
  /* background: rgba(30, 30, 30, 0.99) !important; */
  /* width: 100%; */
  touch-action: auto !important;
  /* touch-action: noone; */
  /* pointer-events: fill; */
  font-family: "Mukta", sans-serif;
  font-size: 14px;
  line-height: 1.75em;
  font-weight: 400;
  color: #999;
}

.count-v-img {
  font-size: 15px;
  color: white;
  margin-left: 10px;
}

.title-v-img {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: white;
  text-align: center;
  max-height: 100%;
  overflow: auto;
}

.header-v-img span.title-v-img {
  /* margin-top: 30px; */
  /* background: rgba(30, 30, 30, 0.99) !important; */
  /* width: 100%; */
  font-family: "Mukta", sans-serif;
  font-size: 14px;
  line-height: 1.75em;
  font-weight: 400;
  color: #999;
}
.buttons-v-img {
  margin-right: 10px;
  margin-left: auto;
  text-align: right;
  /* pointer-events: fill; */
}
.buttons-v-img span {
  cursor: pointer;
  color: #e5e6eb;
  font-size: 30px;
  -webkit-transition: color 0.4s ease-in-out;
  transition: color 0.4s ease-in-out;
  text-decoration: none;
  text-align: center;
}

.vue-pan-zoom-scene {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}
.content-v-img img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  /* top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 15px 15px 35px 15px; */
}
.buttons-v-img {
  margin-right: 10px;
  text-align: right;
}
</style>
