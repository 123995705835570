import emailjs from "emailjs-com";

export default {
  actions: {
    async contactArtict({ commit }, { from_name, from_contact, message }) {
      try {
        emailjs.send(
          process.env.VUE_APP_MAIL_SERVICE_ID,
          process.env.VUE_APP_MAIL_SERVICE_TEMPLATE_ID1,
          {
            from_name,
            from_contact,
            message,
          },
          process.env.VUE_APP_MAIL_SERVICE_USER_ID
        );
      } catch (error) {
        commit("setError", error);
      }
    },
    async requareInfo({ commit }, { from_contact, message }) {
      try {
        emailjs.send(
          process.env.VUE_APP_MAIL_SERVICE_ID,
          process.env.VUE_APP_MAIL_SERVICE_TEMPLATE_ID1,
          {
            from_name: "user",
            from_contact,
            message,
          },
          process.env.VUE_APP_MAIL_SERVICE_USER_ID
        );
      } catch (error) {
        commit("setError", error);
      }
    },
    async commentAddingNotification({ commit }, { comment, author, blog }) {
      try {
        emailjs.send(
          process.env.VUE_APP_MAIL_SERVICE_ID,
          process.env.VUE_APP_MAIL_SERVICE_TEMPLATE_ID2,
          {
            from_name: author,
            blog_url: blog,
            comment,
          },
          process.env.VUE_APP_MAIL_SERVICE_USER_ID
        );
      } catch (error) {
        commit("setError", error);
      }
    },
    async contactSuppot({ commit }, { message }) {
      try {
        emailjs.send(
          process.env.VUE_APP_MAIL_SERVICE_ID,
          process.env.VUE_APP_MAIL_SERVICE_TEMPLATE_ID2,
          {
            message,
          },
          process.env.VUE_APP_MAIL_SERVICE_USER_ID
        );
      } catch (error) {
        commit("setError", error);
      }
    },
  },
};
