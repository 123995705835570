import Home from "../views/Home.vue";
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    name: "home",
    component: Home,
  },
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/About.vue"),
  },
  {
    path: "/portfolio",
    name: "portfolio",
    component: () => import("../views/Portfolio.vue"),
  },
  {
    path: "/picture/:page/:id",
    name: "picture",
    component: () => import("../views/Picture"),
  },
  {
    path: "/blogs",
    name: "blogs",
    component: () => import("../views/Blogs"),
  },
  {
    path: "/blog/:page/:id",
    name: "blog",
    component: () => import("../views/Blog"),
  },
  {
    path: "/contacts",
    name: "contacts",
    component: () => import("../views/Contacts"),
  },
  {
    path: "/hobbies",
    name: "hobbies",
    component: () => import("../views/Hobbies"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
