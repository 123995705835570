const conditions =
  "<h3 style='font-size:bold;'>TERMS AND CONDITIONS</h3>" +
  "<p>Please take the time to read these terms and conditions. By using my Website and the Services and information offered on my Website, you are agreeing to these terms and conditions.</p>" +
  "<p>If you purchase products through my Website, there will be additional terms and conditions relating to the purchase. Please make sure you agree with these terms and conditions, which you will be directed to read prior to making your purchase.</p>" +
  "<p>Services means purchasing and viewing content on this website.</p>" +
  "<p>The Website means the website moldovanandrei.com</p>" +
  "<h3 style='font-size:bold;'>PRICING AND PRODUCT AVAILABILITY</h3>" +
  "<p>Prices are subject to change at any time.</p>" +
  "<p>All prices are in USD and accept payments through Visa and MasterCard or other International money transfer.</p>" +
  "<p>I do not refund any goods for change of mind.</p>" +
  "<p>Prices are subject to change at any time.</p>" +
  "<p>I offer a 100% Money Back Guarantee if you aren’t satisfied with your purchase for a painting, excluding shipping cost.</p>" +
  "<h3 style='font-size:bold;'>SHIPPING RATES AND DELIVERY TIMES</h3>" +
  "<p><ul>" +
  "<li>Please be aware of your countries customs fees and duty tax as we are NOT responsible for any fees that your country may request in order to release the goods you have purchased from us. <br/>Please note: There could be extra delivery delays due to customs within your country.</li>" +
  "<li>I use Standard MOLDAVIAN SERVICE PostALE with tracking for all shipments worldwide.<br/>Please Note: Shipping costs will be automatically added to your cart total. <br/>For more information please click here: https://posta.md/en/posta-calculator" +
  "<ul>" +
  "<li><strong>United States</strong> (2-4 weeks)</li>" +
  "<li><strong>Canada </strong>(3-6 weeks)</li>" +
  "<li><strong>Europe </strong>(1-2 weeks)</li>" +
  "</ul>" +
  "</li>" +
  "<li>Paintings will be shipped out 1-3 business days after purchase has been made.</li>" +
  "<li>I am  not responsible for any Custom fees that your country may request in order to release the goods.</li>" +
  "</ul></p>" +
  "<h3 style='font-size:bold;'>ACCURACY OF CONTENT</h3>" +
  "<p>I have taken proper care and precautions to ensure that the information I provide on this Website is accurate. However, we cannot guarantee, nor do we accept any legal liability arising from or connected to, the accuracy, reliability, currency or completeness of anything contained on this Website or on any linked site.</p>" +
  "<p>The information contained on this Website should not take the place of professional advice.</p>" +
  "<h3 style='font-size:bold;'>USE OF ORIGINAL PAINTINGS AND SKETCHES</h3>" +
  "<p>These are for Private use only. Under no circumstances will you be entitled to:" +
  "<ol>" +
  "<li>Copy or duplicate a Product in whole or in part;</li>" +
  "<li>Sell, rent, lend or sub-licence a Product</li>" +
  "</ol>" +
  "</p>" +
  "<p>The Website is made available for your use on your acceptance and compliance with these terms and conditions. By using this Website, you are agreeing to these terms and conditions.</p>" +
  "<p>You agree that you will use this website in accordance with all applicable local, state, national and international laws, rules and regulations.</p>" +
  "<p>You agree that you will not use, nor will you allow or authorise any third party to use, the Website for any purpose that is unlawful, defamatory, harassing, abusive, fraudulent or obscene way or in any other inappropriate way or in a way which conflicts with the Website or the Services.</p>" +
  "<p>If you contribute to my review or make any public comments on this Website which are, in our opinion, unlawful, defamatory, harassing, abusive, fraudulent or obscene or in any other way inappropriate or which conflict with the Website or the Services offered, then we may at our discretion, refuse to publish such comments and/or remove them from the Website.</p>" +
  "<p>I reserve the right to refuse or terminate service to anyone at any time without notice or reason.</p>" +
  "<h3 style='font-size:bold;'>INTELLECTUAL PROPERTY AND COPYRIGHTS</h3>" +
  "<p>I hold the copyright to the content of this Website, including all uploaded files, layout design, images, data, graphics, articles, file content, codes, news, tutorials, videos, reviews, forum posts and databases contained on the Website or in connection with the Services.</p>" +
  "<p>You must not use or replicate our copyright material other than as permitted by law. Specifically, you must not use or replicate our copyright material for commercial purposes unless expressly agreed to by me, in which case I may require you to sign a Licence Agreement.</p>" +
  "<p>If you wish to use content, images or other of our intellectual property, you should submit your request  at the following email address:<br/>  andrei4743@gmail.com</p>" +
  "<p>The trademarks and logos contained on this Website are trademarks of MOLDOVAN ANDREI.</p>" +
  "<h3 style='font-size:bold;'>LINKS TO EXTERNAL WEBSITES</h3>" +
  "<p>This Website may contain links that direct you outside of this Website.</p>" +
  "<p>You may link to our articles or home page.</p>" +
  "<p>All personal information you give me will be dealt with in a confidential manner in accordance with Privacy Policy. However, due to circumstances outside of our control, I cannot guarantee that all aspects of your use of this Website will be confidential due to the potential ability of third parties to intercept and access such information.</p>";
export default conditions;
