<template>
  <svg
    v-if="icon"
    :width="icon.props.width"
    :height="icon.props.height"
    :viewBox="icon.props.viewBox"
    :fill="icon.props.fill"
  >
    <path :d="icon.path.d" :fill="icon.path.fill" />
  </svg>
</template>

<script>
import icons from "@/assets/data/icons";

export default {
  name: "BaseIcons",
  data: () => {
    return { icon: null };
  },
  props: {
    name: String,
  },
  mounted() {
    this.icon = icons[this.name];
  },
  computed: {},
};
</script>

<style lang="scss">
// svg {
//   fill: #999999;
// }
</style>
