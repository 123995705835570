import {
  child,
  get,
  getDatabase,
  push,
  ref,
  set,
  update,
} from "firebase/database";

import _ from "lodash";

export default {
  actions: {
    async fetchArticles({ commit }, home) {
      const dbRef = ref(getDatabase());
      let articles = [];
      try {
        await get(child(dbRef, process.env.VUE_APP_DB_BLOG_LOCATION))
          .then((blog) => {
            blog = blog.val();
            articles = Object.keys(blog).map((key) => ({
              ...blog[key],
              id: key,
            }));

            //Order descendente by data
            articles = _.orderBy(articles, ["date"], ["desc"]);

            //If is home screen then get only 3 articles
            if (home) articles = articles.slice(0, 3);
          })
          .catch((error) => {
            console.error(error);
            commit("setError", error);
          });
      } catch (error) {
        commit("setError", error);
      }
      return articles;
    },

    async fetchArticleById({ commit }, id) {
      const dbRef = ref(getDatabase());
      let article = {};
      try {
        await get(child(dbRef, `${process.env.VUE_APP_DB_BLOG_LOCATION}/${id}`))
          .then((art) => {
            article = art.val();

            let comments = article.comments
              ? Object.keys(article.comments).map(function (key) {
                  let comment = article.comments[key];
                  let replies = comment.reply
                    ? Object.keys(comment.reply).map((keyr) => ({
                        ...comment.reply[keyr],
                        id: keyr,
                      }))
                    : [];

                  //Order descendente by data
                  if (replies.length > 0)
                    replies = _.orderBy(replies, ["date"], ["desc"]);

                  comment.reply = replies;

                  return {
                    ...comment,
                    id: key,
                  };
                })
              : [];

            //Order descendente by data
            if (comments.length > 0)
              comments = _.orderBy(comments, ["date"], ["desc"]);

            article.comments = comments;
          })
          .catch((error) => {
            console.error(error);
            commit("setError", error);
          });
      } catch (error) {
        commit("setError", error);
      }
      return { ...article, id };
    },
    async addComment({ commit, dispatch }, { blogId, formData }) {
      return new Promise((resolve, reject) => {
        try {
          //loginAnonymously
          dispatch("loginAnonymously")
            .then(() => {
              //Add comment to db
              const comment = push(
                ref(
                  getDatabase(),
                  `${process.env.VUE_APP_DB_BLOG_LOCATION}/${blogId}/comments`
                )
              );
              formData.date = new Date().getTime();

              set(comment, {
                ...formData,
              })
                .then(function () {
                  resolve({ code: 0, data: comment.key });
                })
                .catch((error) => {
                  throw error;
                });
            })
            .catch((error) => {
              throw error;
            });
        } catch (error) {
          commit("setError", error);
          reject({ code: -99, message: error, function: "addComment" });
        }
      });
    },
    async addCommentReply(
      { commit, dispatch },
      { blogId, commentId, formData }
    ) {
      return new Promise((resolve, reject) => {
        try {
          //loginAnonymously
          dispatch("loginAnonymously")
            .then(() => {
              //Add comment to db
              const reply = push(
                ref(
                  getDatabase(),
                  `${process.env.VUE_APP_DB_BLOG_LOCATION}/${blogId}/comments/${commentId}/reply`
                )
              );
              formData.date = new Date().getTime();

              set(reply, {
                ...formData,
              })
                .then(function () {
                  resolve({ code: 0, data: reply.key });
                })
                .catch((error) => {
                  throw error;
                });
            })
            .catch((error) => {
              throw error;
            });
        } catch (error) {
          commit("setError", error);
          reject({ code: -99, message: error, function: "addCommentReply" });
        }
      });
    },
    async addLikesCommentById({ commit }, { blogId, commentId, likes }) {
      return new Promise((resolve, reject) => {
        try {
          const comment = {};
          comment[
            `${process.env.VUE_APP_DB_BLOG_LOCATION}/${blogId}/comments/${commentId}/likes`
          ] = likes;
          update(ref(getDatabase()), comment)
            .then(() => {
              resolve({ code: 0 });
            })
            .catch((error) => {
              console.error(error);
              throw error;
            });
        } catch (error) {
          commit("setError", error);
          reject({ code: -99, message: error, function: "addComment" });
        }
      });
    },
  },
};
