<template>
  <ul class="social-icons">
    <li class="social-icons-item" v-for="(social, idx) in socials" :key="idx">
      <a class="social-link" :href="social.linck" target="_blank">
        <b-icon class="social-icon" :icon="social.icon"></b-icon>
      </a>
    </li>
  </ul>
</template>

<script>
import { BIcon } from "bootstrap-vue";
export default {
  data: () => {
    return {
      socials: [
        {
          icon: "instagram",
          linck:
            "https://instagram.com/andrei_moldovan_artist?utm_medium=copy_link",
        },
        {
          icon: "facebook",
          linck: "https://www.facebook.com/moldan.124",
        },
        {
          icon: "youtube",
          linck: "https://www.youtube.com/channel/UC-feZ6zrnF4gs-Qmrvawojw",
        },
        // {
        //   icon: "pinterest",
        //   linck: "https://www.pinterest.com/",
        // },
      ],
    };
  },
  components: {
    BIcon,
  },
};
</script>
<style lang="scss">
@import "@/assets/css/socialLinks";
</style>
